var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header",class:{ 'header-sub': _vm.isSub }},[(_vm.showModal)?_c('modal',{attrs:{"width":'490px'},on:{"on-closed":function($event){_vm.showModal = false}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('modal-claim',{on:{"on-closed":function($event){_vm.showModal = false}}})]},proxy:true}],null,false,2387725090)}):_vm._e(),_c('div',{staticClass:"header-grid"},[_c('div',{staticClass:"header-gridItem"},[(_vm.$mq === 'desktop')?_c('div',{staticClass:"logo",on:{"click":function($event){_vm.$route.path !== '/' ? _vm.$router.push({ path: '/' }) : ''}}},[(_vm.isSub)?_c('div',{staticClass:"logo-container"},[(_vm.isSub)?_c('img',{attrs:{"src":require("@/assets/logo-sub.png"),"alt":""}}):_vm._e(),_c('div',{staticClass:"beta beta-sub"},[_vm._v("Beta")])]):_c('div',{staticClass:"logo-container"},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"alt":""}}),_c('div',{staticClass:"beta"},[_vm._v("Beta")])])]):_c('div',{staticClass:"logo-tablet",on:{"click":function($event){_vm.$route.path !== '/' ? _vm.$router.push({ path: '/' }) : ''}}},[(_vm.isSub)?_c('div',{staticClass:"logo-container"},[_c('img',{attrs:{"src":require("@/assets/mobile-logo-sub.png"),"alt":"","width":"105","height":"30"}}),_c('div',{staticClass:"beta beta-sub"},[_vm._v("mainnet")])]):_c('div',{staticClass:"logo-container"},[_c('img',{attrs:{"src":require("@/assets/mobile-logo.png"),"alt":"","width":"105","height":"30"}}),_c('div',{staticClass:"beta"},[_vm._v("Beta")])])])]),_c('div',{staticClass:"header-gridItem"},[_c('div',{staticClass:"menu"},[_c('router-link',{staticClass:"menu-item",class:{
            'menu-item-sub': _vm.isSub,
            selected: _vm.$route.path.includes('election'),
          },staticStyle:{"margin-right":"60px"},attrs:{"to":'/election'}},[_vm._v(" DAO Candidates ")]),_c('router-link',{staticClass:"menu-item",class:{
            'menu-item-sub': _vm.isSub,
            selected: _vm.$route.path.includes('propose'),
          },staticStyle:{"margin-right":"60px"},attrs:{"to":'/propose'}},[_vm._v(" Propose ")]),_c('router-link',{staticClass:"menu-item",class:{
            'menu-item-sub': _vm.isSub,
            selected: _vm.$route.path.includes('agenda'),
          },attrs:{"to":'/agenda'}},[_vm._v(" Agenda ")])],1)]),_c('div',{staticClass:"header-gridItem",staticStyle:{"justify-content":"flex-end"}},[_c('connect-wallet',{attrs:{"is-sub":_vm.isSub}}),(_vm.account !== '' && _vm.isCandidate)?_c('div',{staticClass:"container"},[_c('div',[_c('button',{class:{ claim: _vm.isSub },on:{"click":function($event){_vm.showModal = true}}},[_vm._v(" Claim ")])])]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }