var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-agenda-info"},[(_vm.$mq === 'desktop')?_c('div',{staticClass:"button"},[_c('button-step',{staticClass:"back",attrs:{"type":'prev',"name":'BACK TO ALL AGENDAS'},on:{"on-clicked":_vm.back}}),_c('div',[(_vm.getAgendaPrevButtonState(_vm.agendaId))?_c('button-step',{staticClass:"prev",attrs:{"type":'prev',"name":'PREVIOUS AGENDA'},on:{"on-clicked":_vm.prev}}):_vm._e(),(_vm.getAgendaNextButtonState(_vm.agendaId))?_c('button-step',{staticClass:"next",attrs:{"type":'next',"name":'NEXT AGENDA'},on:{"on-clicked":_vm.next}}):_vm._e()],1)],1):(_vm.$mq === 'tablet')?_c('div',{staticClass:"button-mobile"},[_c('button-step',{staticClass:"back",staticStyle:{"width":"140px"},attrs:{"type":'prev',"name":'BACK TO ALL'},on:{"on-clicked":_vm.back}}),_c('div',[(_vm.getAgendaPrevButtonState(_vm.agendaId))?_c('button-step',{staticClass:"prev",staticStyle:{"width":"130px"},attrs:{"type":'prev',"name":'PREVIOUS'},on:{"on-clicked":_vm.prev}}):_vm._e(),(_vm.getAgendaNextButtonState(_vm.agendaId))?_c('button-step',{staticClass:"next",staticStyle:{"width":"130px"},attrs:{"type":'next',"name":'NEXT'},on:{"on-clicked":_vm.next}}):_vm._e()],1)],1):_c('div',{staticClass:"button-mobile"},[_c('button-step',{staticClass:"back",attrs:{"type":'prev',"name":'BACK'},on:{"on-clicked":_vm.back}}),_c('div',[(_vm.getAgendaPrevButtonState(_vm.agendaId))?_c('button-step',{staticClass:"prev",attrs:{"type":'prev',"name":'PREVIOUS'},on:{"on-clicked":_vm.prev}}):_vm._e(),(_vm.getAgendaNextButtonState(_vm.agendaId))?_c('button-step',{staticClass:"next",attrs:{"type":'next',"name":'NEXT'},on:{"on-clicked":_vm.next}}):_vm._e()],1)],1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-sub-container"},[_c('div',{class:{
        'agenda-typeA': _vm.agendaType(_vm.agendaId) === 'A',
        'agenda-typeB': _vm.agendaType(_vm.agendaId) === 'B',
      }},[_vm._v(" #"+_vm._s(_vm.$route.params.id)+" ")]),_c('div',{staticClass:"agenda-label"},[_vm._v("Type")]),_c('div',{class:{
        'agenda-typeA': _vm.agendaType(_vm.agendaId) === 'A',
        'agenda-typeB': _vm.agendaType(_vm.agendaId) === 'B',
      }},[_vm._v(" "+_vm._s(_vm.agendaType(_vm.agendaId))+" ")]),_c('div',{staticClass:"content-sub-date"},[_vm._v(" is Posted "+_vm._s(_vm._f("date3")(_vm.creationTime.tCreationDate))+" ")]),(_vm.agendaType(_vm.agendaId) === 'A')?_c('img',{attrs:{"src":require("@/assets/poll-time-active-icon.svg"),"alt":"","width":"14","height":"14"}}):_c('img',{attrs:{"src":require("@/assets/poll-time-active-icon-typeB.svg"),"alt":"","width":"14","height":"14"}}),_c('span',{staticClass:"content-sub-spare-time"},[_vm._v(" "+_vm._s(_vm._f("votingTime")(_vm.creationTime)))])]),(_vm.$mq !== 'mobile')?_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.agendaTitle(_vm.agendaId)))]):_c('div',[(_vm.account && _vm.checkStatus)?_c('div',[_c('div',{staticClass:"title",staticStyle:{"margin-bottom":"0px"}},[_vm._v(_vm._s(_vm.title))]),_c('button',{staticClass:"update-btn",staticStyle:{"margin-top":"14px","margin-bottom":"30px"},on:{"click":function($event){return _vm.endAgenda()}}},[_vm._v(" End Agenda ")])]):_c('div',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))])])]),(_vm.$mq !== 'mobile')?_c('div',{staticClass:"selector"},[_c('div',{class:{ 'selected': _vm.currentSelector === 0,
                     'selected-typeB': _vm.agendaType(_vm.agendaId) === 'B' && _vm.currentSelector == 0 },on:{"click":function($event){_vm.currentSelector = 0}}},[_vm._v(" Info ")]),_c('div',{class:{ 'selected': _vm.currentSelector === 1,
                     'selected-typeB': _vm.agendaType(_vm.agendaId) === 'B' && _vm.currentSelector == 1 },on:{"click":function($event){_vm.currentSelector = 1}}},[_vm._v(" Description ")]),_c('div',{class:{ 'selected': _vm.currentSelector === 2,
                     'selected-typeB': _vm.agendaType(_vm.agendaId) === 'B' && _vm.currentSelector === 2 },on:{"click":function($event){_vm.currentSelector = 2}}},[_vm._v(" On-Chain Effects ")]),_c('div',{staticClass:"comment",class:{ 'selected': _vm.currentSelector === 3,
                     'selected-typeB': _vm.agendaType(_vm.agendaId) === 'B' && _vm.currentSelector === 3 },on:{"click":function($event){_vm.currentSelector = 3}}},[_vm._v(" Comments ("+_vm._s(_vm.voted)+") ")]),_c('span',{staticClass:"space"}),(_vm.account && _vm.checkStatus)?_c('button',{staticClass:"update-btn",on:{"click":function($event){return _vm.endAgenda()}}},[_vm._v(" End Agenda ")]):_vm._e()]):_c('div',{staticClass:"selector-mobile"},[_c('div',{class:{ 'selected': _vm.currentSelector === 0,
                     'selected-typeB': _vm.agendaType(_vm.agendaId) === 'B' && _vm.currentSelector == 0 },on:{"click":function($event){_vm.currentSelector = 0}}},[_vm._v(" Info ")]),_c('div',{class:{ 'selected': _vm.currentSelector === 1,
                     'selected-typeB': _vm.agendaType(_vm.agendaId) === 'B' && _vm.currentSelector == 1 },on:{"click":function($event){_vm.currentSelector = 1}}},[_vm._v(" Description ")]),_c('div',{class:{ 'selected': _vm.currentSelector === 2,
                     'selected-typeB': _vm.agendaType(_vm.agendaId) === 'B' && _vm.currentSelector === 2 },on:{"click":function($event){_vm.currentSelector = 2}}},[_vm._v(" On-Chain Effects ")]),_c('div',{staticClass:"comment",class:{ 'selected': _vm.currentSelector === 3,
                     'selected-typeB': _vm.agendaType(_vm.agendaId) === 'B' && _vm.currentSelector === 3 },on:{"click":function($event){_vm.currentSelector = 3}}},[_vm._v(" Comments ("+_vm._s(_vm.voted)+") ")])]),_c('div',{staticClass:"divider"}),(_vm.currentSelector === 0)?_c('agenda-info'):(_vm.currentSelector === 1)?_c('agenda-description'):(_vm.currentSelector === 2)?_c('agenda-on-chain'):(_vm.currentSelector === 3)?_c('agenda-vote'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }